/* eslint-disable no-console */
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';

const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);
const reactPlugin = new ReactPlugin();
const key = process.env.REACT_APP_APPINSIGHTS_KEY;
let appInsightsInitialization: ApplicationInsights | null = null;

const log = (message: string, severityLevel: SeverityLevel, details?: { [key: string]: any }) => {
    const shouldLogToAppInsights = process.env.NODE_ENV && process.env.NODE_ENV === 'production';

    if (shouldLogToAppInsights) {
        if (!appInsightsInitialization?.appInsights?.isInitialized()) {
            appInsightsInitialization = new ApplicationInsights({
                config: {
                    connectionString: key,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory },
                    },
                },
            });
            appInsightsInitialization.loadAppInsights();
        }
        appInsightsInitialization?.appInsights?.trackTrace(
            {
                message,
                severityLevel,
            },
            details,
        );
    } else {
        switch (severityLevel) {
            case SeverityLevel.Verbose:
                console.trace(message, details);
                break;
            case SeverityLevel.Information:
                console.info(message, details);
                break;
            case SeverityLevel.Warning:
                console.warn(message, details);
                break;
            case SeverityLevel.Error:
                console.error(message, details);
                break;
            case SeverityLevel.Critical:
                console.error(message, details);
        }
    }
};

const verbose = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Verbose, details);
};

const info = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Information, details);
};

const warn = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Warning, details);
};

const error = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Error, details);
};

const critical = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Critical, details);
};

export const Logger = {
    verbose,
    info,
    warn,
    error,
    critical,
};
