import { useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { AxiosError } from 'axios';
import authService from 'src/components/authorization/AuthorizeService';
import { OverlayProvider } from './utils/overlay-provider';
import { Maybe } from './types/maybe';
import { Layout } from './components/layout';
import QueryAuthorization from './queryAuthorization';
import { ArticleDetailsProvider } from './utils/contexts/articleContext';
import { PageBodyDetailsProvider } from './utils/contexts/pageBodyContext';
import { mediaHubDb } from './utils/database';
import PWAInstallation from './pages/pwa-installation';
import { PDFViewerContextProvider } from './utils/contexts/pdfViewerContext';
import { Skeleton } from './components/loading-skeleton';
import { SkeletonType } from './components/loading-skeleton/dto';
import GoogleTrackingServices from './services/google-tracking-services';
import { PageConfigurationType } from '@/types/apiDtos';
import PageService from '@/services/page-service';
import AuthorizeRoute from '@/components/authorization/AuthorizeRoute';
import { Logger } from '@/utils/logger';
import ApiAuthorizationRoutes from '@/components/authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '@/components/authorization/ApiAuthorizationConstants';
import SynchronizationService from '@/services/synchronization-service';
import { HeadlessPageContextProvider } from '@/utils/contexts/headlessPageContext';
import ThemeProvider from '@/utils/contexts/theme-provider';
import TimelyEvent from '@/pages/timely-event';

const NotFound = loadable(() => import('@/pages/404'), {
    fallback: <Skeleton variant={SkeletonType.FullPageSpinner} />,
});
const Forbidden = loadable(() => import('@/pages/forbidden'), {
    fallback: <Skeleton variant={SkeletonType.FullPageSpinner} />,
});
const Dynamic = loadable(() => import('@/pages/dynamic'), {
    fallback: <Skeleton variant={SkeletonType.FullPageSpinner} />,
});

export const ConfigurationContext = createContext<{
    pageConfigurationData: Maybe<PageConfigurationType>;
}>({
    pageConfigurationData: null,
});

export const useConfiguration = () => useContext(ConfigurationContext);

function App() {
    const [headerFooterLoading, setHeaderFooterLoading] = useState<boolean>(true);
    const [pageConfigurationData, setPageConfigurationData] = useState<PageConfigurationType>();
    const [showFooter, setFooterVisibility] = useState<boolean>(true);
    const [showHeader, setHeaderVisibility] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    const unauthenticatedFrontPageUri = '/home';
    const authenticatedFrontPageUri =
        pageConfigurationData?.alternativeFrontPageUri ||
        pageConfigurationData?.frontPageUri ||
        unauthenticatedFrontPageUri;

    useEffect(() => {
        authService.getAccessToken().then((value) => {
            if (value) {
                mediaHubDb.userData.put({ key: 'accessToken', token: value }, 'accessToken');
            }
        });

        authService.isAuthenticated().then((isAuthenticated) => {
            setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                SynchronizationService.synchronizeUser();
            }
        });
    }, []);

    useEffect(() => {
        const handleOnline = () => window.location.reload();
        const handleOffline = () => {
            window.location.reload();
            window.scrollTo({ top: 0 });
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        const initializeApp = () => {
            PageService.getPageConfiguration('en')
                .then((configuration: PageConfigurationType) => {
                    if (configuration) {
                        setPageConfigurationData(configuration);
                        setHeaderFooterLoading(false);
                    }
                })
                .catch((e: AxiosError) => {
                    Logger.error(`An error occurred while displaying header/footer data${e}`);
                });
        };

        (async () => {
            const isTokenExpired = await authService.isTokenExpired();
            authService.subscribe(() => {
                authService.isAuthenticated().then((r) => {
                    setIsAuthenticated(r);
                });
            });

            if (isTokenExpired) {
                await authService.tryRefreshToken();
            }
            initializeApp();
            GoogleTrackingServices.initializeTracking();
        })();
    }, [isAuthenticated]);

    return (
        <Router>
            <div>
                <QueryAuthorization />
            </div>
            <OverlayProvider>
                <ConfigurationContext.Provider value={{ pageConfigurationData }}>
                    <ThemeProvider>
                        <PDFViewerContextProvider>
                            <ArticleDetailsProvider>
                                <Switch>
                                    <Route path="/timelyEvent">
                                        <TimelyEvent></TimelyEvent>
                                    </Route>
                                    <Route path="/headless/*">
                                        <HeadlessPageContextProvider isMobile={false}>
                                            <Dynamic
                                                setFooterVisibilityFunc={setFooterVisibility}
                                                setHeaderVisibilityFunc={setHeaderVisibility}
                                                showFooter={showFooter}
                                                headerFooterLoading={headerFooterLoading}
                                                headless={true}
                                            />
                                        </HeadlessPageContextProvider>
                                    </Route>
                                    <Route path="/mobile/*">
                                        <HeadlessPageContextProvider isMobile={true}>
                                            <PageBodyDetailsProvider
                                                menu={pageConfigurationData?.defaultHeader.headerMenu}
                                            >
                                                <Dynamic
                                                    setFooterVisibilityFunc={setFooterVisibility}
                                                    setHeaderVisibilityFunc={setHeaderVisibility}
                                                    showFooter={showFooter}
                                                    headerFooterLoading={headerFooterLoading}
                                                    headless={true}
                                                />
                                            </PageBodyDetailsProvider>
                                        </HeadlessPageContextProvider>
                                    </Route>
                                    <Route path="/pwa-installation">
                                        <PWAInstallation />
                                    </Route>
                                    <Route path="/*">
                                        <Layout
                                            pageConfigurationData={pageConfigurationData}
                                            isAuthenticated={isAuthenticated}
                                            showHeader={showHeader}
                                            headerFooterLoading={headerFooterLoading}
                                        >
                                            <PageBodyDetailsProvider
                                                menu={pageConfigurationData?.defaultHeader.headerMenu}
                                            >
                                                <Switch>
                                                    <AuthorizeRoute path="/404" component={NotFound}>
                                                        <NotFound />
                                                    </AuthorizeRoute>
                                                    <Route path={['/404', '/en/404']}>
                                                        <NotFound />
                                                    </Route>
                                                    <AuthorizeRoute path="/forbidden" component={Forbidden}>
                                                        <Forbidden />
                                                    </AuthorizeRoute>
                                                    <Route path={['/forbidden', '/en/forbidden']}>
                                                        <Forbidden />
                                                    </Route>
                                                    <Route
                                                        path={ApplicationPaths.ApiAuthorizationPrefix}
                                                        component={ApiAuthorizationRoutes}
                                                    />

                                                    {isAuthenticated !== undefined && pageConfigurationData && (
                                                        <Route path="/" exact>
                                                            <Redirect
                                                                to={
                                                                    isAuthenticated === true
                                                                        ? authenticatedFrontPageUri
                                                                        : unauthenticatedFrontPageUri
                                                                }
                                                            />
                                                        </Route>
                                                    )}

                                                    <Route path="/*">
                                                        <Dynamic
                                                            setFooterVisibilityFunc={setFooterVisibility}
                                                            setHeaderVisibilityFunc={setHeaderVisibility}
                                                            showFooter={showFooter}
                                                            headerFooterLoading={headerFooterLoading}
                                                        />
                                                    </Route>
                                                </Switch>
                                            </PageBodyDetailsProvider>
                                        </Layout>
                                    </Route>
                                </Switch>
                            </ArticleDetailsProvider>
                        </PDFViewerContextProvider>
                    </ThemeProvider>
                </ConfigurationContext.Provider>
            </OverlayProvider>
        </Router>
    );
}

export default App;
