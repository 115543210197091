import { IconType } from 'src/components/icon-generator/dto';
import { Maybe } from 'src/types/maybe';

export enum ButtonType {
    Primary,
    PrimaryReversed,
    Secondary,
    Tertiary,
    Transparent,
    PureText,
}

export enum Size {
    Large = 'lg',
    Medium = 'md',
    Small = 'sm',
}

type FFButtonProps = {
    text?: string;
    link?: Maybe<string>;
    size?: Maybe<Size>;
    disabled?: Maybe<boolean>;
    loading?: Maybe<boolean>;
    kind?: Maybe<ButtonType>;
    func?: Maybe<any>;
    ariaLabel?: Maybe<string>;
    className?: Maybe<string>;
    prefixIcon?: Maybe<IconType | string>;
    postfixIcon?: Maybe<IconType | string>;
    prefixIconClassName?: Maybe<string>;
    postfixIconClassName?: Maybe<string>;
    previousPageTitle?: Maybe<string>;
};

export default FFButtonProps;
