import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import uuid from 'src/utils/uuid';
import { FMHLink } from 'src/atoms/fmh-link';
import { isArrayInvalid, isDirRtl } from 'src/utils/check';
import useArticleDetails from 'src/utils/contexts/articleContext';
import { Maybe } from 'src/types/maybe';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import Styles from './fmh-breadcrumbs.module.scss';
import { BreadcrumbsItem } from './dto';
import { FMHBreadcrumbsProps } from '.';

type TraversableMenu = {
    uri?: Maybe<string>;
    title: string;
    children?: Maybe<Array<TraversableMenu>>;
};

const isPathSubPath = (path: string, subPath: string) => {
    return path === subPath || path.indexOf(`${subPath}/`) === 0 || path.indexOf(`${subPath}?`) === 0;
};

const getBestBreadcrumbsItems = (menu: Array<TraversableMenu>, path: string): Array<BreadcrumbsItem> => {
    let bestResult: Array<BreadcrumbsItem> = [];

    for (let i = 0; i < menu.length; i++) {
        const menuItem = menu[i];
        let currentResult: Array<BreadcrumbsItem> = [];

        if (menuItem.uri && isPathSubPath(path, menuItem.uri)) {
            currentResult = [{ ...menuItem }];
        }
        if (menuItem.children && menuItem.children.length > 0) {
            const childrenBestResult: Array<BreadcrumbsItem> = getBestBreadcrumbsItems(menuItem.children, path);

            if (childrenBestResult.length > 0) {
                if (currentResult.length > 0) {
                    currentResult = currentResult.concat(childrenBestResult);
                } else {
                    currentResult = [{ ...menuItem }];
                    currentResult = currentResult.concat(childrenBestResult);
                }
            }
        }
        if (currentResult.length > bestResult.length) {
            bestResult = currentResult;
        }
    }

    return bestResult;
};

const removeLocaleFromSlug = (url: string): string | null => {
    if (!url) return null;
    const localeIdLength = 2;
    if (url.length > localeIdLength + 2 && url[0] === '/' && url[localeIdLength + 1] === '/') {
        return url.slice(localeIdLength + 1);
    }
    return url;
};

const unlocalizeMenu = (menu: Array<TraversableMenu>): Array<TraversableMenu> => {
    return menu.map((menuItem) => {
        return {
            title: menuItem.title,
            uri: removeLocaleFromSlug(menuItem.uri ?? ''),
            children: menuItem.children ? unlocalizeMenu(menuItem.children) : null,
        };
    });
};

const pathsNotInMenu = ['news/', 'media-video/'];

const FMHBreadcrumbs: FC<FMHBreadcrumbsProps> = (props: FMHBreadcrumbsProps): JSX.Element => {
    const uId = uuid();
    const location = useLocation();
    const [breadcrumbsContent, setBreadcrumbsContent] = useState<BreadcrumbsItem[]>();
    const { articleName, relativeUrl } = useArticleDetails();
    const history = useHistory();

    const numberOfBreadcrumbsItemsToDisplay = 3;

    const renderArrow = (isMobile: boolean): JSX.Element => {
        const rightArrow = <span className={Styles.arrow}>/</span>;
        const leftArrow = <span className={Styles.arrow}>\</span>;

        if (isMobile) return <></>;

        if (isDirRtl()) return leftArrow;

        return rightArrow;
    };

    const checkIfPathIncludesPagesNotInMenu = useCallback(
        (str: string): boolean => {
            return location.pathname.includes(str);
        },
        [location.pathname],
    );

    useEffect(() => {
        const path: string | null = location.pathname.includes(pathsNotInMenu[0])
            ? removeLocaleFromSlug(`/${relativeUrl}`)
            : removeLocaleFromSlug(location.pathname);
        if (!path) return;
        if (isArrayInvalid(props.menu)) setBreadcrumbsContent([]);

        const unLocalizedMenu = unlocalizeMenu(props.menu);
        const content = getBestBreadcrumbsItems(unLocalizedMenu, path);

        // Only render article name if path is an article
        if (!pathsNotInMenu.some(checkIfPathIncludesPagesNotInMenu)) {
            setBreadcrumbsContent(content.slice(-numberOfBreadcrumbsItemsToDisplay));
            return;
        }

        setBreadcrumbsContent(
            [...content, { uri: null, title: articleName ?? '' }].slice(-numberOfBreadcrumbsItemsToDisplay),
        );
    }, [articleName, checkIfPathIncludesPagesNotInMenu, location.pathname, props.menu, relativeUrl]);

    const handleGoBack = () => {
        if (!breadcrumbsContent) return;

        // last item in breadcrumbs should be the current location;
        const currentIndex = breadcrumbsContent.length - 1;
        const prevIndex = currentIndex - 1;

        // go back 1 level in the breadcrumbs
        if (prevIndex >= 0 && breadcrumbsContent[prevIndex].uri) {
            history.replace(`${breadcrumbsContent[prevIndex].uri}`);
        } else {
            history.goBack();
        }
    };

    return (
        <>
            {breadcrumbsContent && (breadcrumbsContent.length > 1 || articleName) && (
                <div className="ff-my-24">
                    <ul className={`d-none d-md-flex ${Styles.breadcrumbsList}`}>
                        {breadcrumbsContent.map((value, key) => {
                            return (
                                <li key={uId + key} className={Styles.breadcrumbsItem}>
                                    {renderArrow(false)}
                                    {key === breadcrumbsContent.length - 1 || value.uri === null ? (
                                        <p>{value.title}</p>
                                    ) : (
                                        <FMHLink href={value.uri}>{value.title}</FMHLink>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                    <div className={`d-flex d-md-none ${Styles.breadcrumbsList}`} onClick={handleGoBack}>
                        <Icon icon={IconType.ChevronLeft} className={Styles.backChevron} />
                        <p>Back</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default FMHBreadcrumbs;
