import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { FMHBreadcrumbsBanner } from 'src/molecules/fmh-breadcrumbs-banner';
import { FirstLevelMenu } from 'src/organisms/fmh-header/dto';
import { LanguageWithLinkType } from 'src/templates/article-template/dto';
import useHeadlessPageDetails from './headlessPageContext';

export type PageBodyContextLanguageDropdownType = {
    languageDropdown: Array<LanguageWithLinkType>;
    currentLocale: string;
};
interface PageBodyContextType {
    languageDropdownData?: PageBodyContextLanguageDropdownType;
    setLanguageDropdownData: React.Dispatch<React.SetStateAction<PageBodyContextLanguageDropdownType | undefined>>;
    setShouldShowLanguageDropdown: React.Dispatch<React.SetStateAction<boolean>>;
    setShouldShowBreadcrumbsBanner: React.Dispatch<React.SetStateAction<boolean>>;
}
const PageBodyContext: React.Context<PageBodyContextType> = createContext({} as PageBodyContextType);

// PageBodyDetailsProvider to wrap around our main App.tsx component
export const PageBodyDetailsProvider = ({ children, menu }: { menu?: Array<FirstLevelMenu>; children: ReactNode }) => {
    const [languageDropdownData, setLanguageDropdownData] = useState<PageBodyContextLanguageDropdownType>();
    const [shouldShowLanguageDropdown, setShouldShowLanguageDropdown] = useState<boolean>(true);
    const { isMobile: isWebview } = useHeadlessPageDetails();
    const [shouldShowBreadcrumbsBanner, setShouldShowBreadcrumbsBanner] = useState(true);

    // Using React Memo, to ensure that the DetailsProvider only update when the overlay state changes. Otherwise the whole tree under the ArticleDetailsProvider will re-render children at every state and value change
    const details = useMemo(
        () => ({
            languageDropdownData,
            setLanguageDropdownData,
            setShouldShowLanguageDropdown,
            setShouldShowBreadcrumbsBanner,
        }),
        [languageDropdownData],
    );

    return (
        <PageBodyContext.Provider value={details}>
            {!isWebview && shouldShowBreadcrumbsBanner && (
                <FMHBreadcrumbsBanner
                    menu={menu}
                    languagesDropdown={shouldShowLanguageDropdown ? languageDropdownData?.languageDropdown : undefined}
                    locale={languageDropdownData?.currentLocale}
                />
            )}

            {children}
        </PageBodyContext.Provider>
    );
};

// usePageBodyDetails is a context helper function to be used inside the components, that need information about overlay overlay
const usePageBodyDetails = () => {
    return useContext(PageBodyContext);
};
export default usePageBodyDetails;
